@font-face {
  font-family: 'SuisseIntlCond-Light-WebS';
  src: url('../../assets/fonts/SuisseIntlCond-Light-WebS.ttf') format('truetype');
}

@font-face {
  font-family: 'SuisseIntlCond-Regular-WebS';
  src: url('../../assets/fonts/SuisseIntlCond-Regular-WebS.ttf') format('truetype');
}


.Templateop6{
  width: 100%;
  height: 100%;
}

.jekformsTOP6{
    width: 10rem;
    height: 2.35rem;
    margin-top: 5%;
    margin-left: 5%;
  }

.contentT6{
  width: 100%;
  text-align: center;
  margin-left: 50%;
  transform: translateX(-50%);
  margin-bottom: 10%;
}

.titulotop6{
  margin-bottom: 4%;
  font-family: 'SuisseIntlCond-Light-WebS';
  font-weight: 400;
}

.imagensWrapper{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
}

.imagensWrapper img{
  border-radius:0.375rem;;
  width: 20.25rem;
  height: 24rem;
  transition: transform 2s ease-out;
  cursor: pointer;
  object-fit: cover;
  border-color: #1690C3;
}

.imagensWrapper img:hover{
  transform: scale(1.02);
}

.imagens-selecionada{
  border: 2px solid #1690C3;

}

#Imagem-1{
  margin-right: 1%;
  
}

#Imagem-3{
  margin-left: 1%;
}

.Button-containerop6 {
  width: 6%;
  height: 2.4%;
  margin-bottom: 5%;
  margin-left:87%;
 
}

.Button {
  width: 100%;
  height: 100%;
  transition: transform 2s ease-out;
}

.Button:hover {
  transform: scale(1.02);
  border-bottom: 2px solid #1690C3;
}


@media( max-width: 1355px){

  .jekformsTOP6{
    width: 7rem;
    height: 2.35rem;
    margin-top: 4%;
    margin-left: 5%;
  }

  .contentT6{
    width: 63%;
    text-align: center;
    margin-left: 49%;
    transform: translateX(-50%);
    margin-bottom: 3%;
    margin-top: 3%;
  }

  .imagensWrapper{
    display: flex;
    flex-direction: row;
    margin-left: 3%;
  }

  .imagensWrapper img{
    width: 14.25rem;
    height: 20.75rem; 
    transition: transform 2s ease-out;
    cursor: pointer;
  }

  
  .Button {
    width: 90%;
    transition: transform 2s ease-out;
  }

  .Button:hover {
    transform: scale(1.02);
    border-bottom: 2px solid #1690C3;
  }

}

@media(min-width: 1700px)
{

  .contentT6{
   margin-top: 4%;
  }

    
  .Button {
    width: 70%;
  }

}



@media( max-width: 390px){
  
  .jekformsTOP6{
    width: 7rem;
    height: 1.9rem;
    margin-top: 10%;
}

.titulotop6{
  margin-bottom: 6%;
  font-size: 1rem;
  font-family: 'SuisseIntlCond-Light-WebS';
  font-weight: 400;
}


.contentOP5{

  margin-left: 49%;
  transform: translateX(-47%);
  margin-top: 30%;
  width: 15.5rem;
  height: 15.375rem;

}

.imagensWrapper{
  width: fit-content;
  display: flex;
  flex-direction: row;
  margin-left: 3%;
}

.imagensWrapper img{
  width: 14.25rem;
  height: 12.75rem; 
  transition: transform 2s ease-out;
  cursor: pointer;
}




.Button-containerop6 {
width: 3.5rem;
height: 1.4375rem;
margin-top: 7%;
margin-left:80%;

}


}
