
@font-face {
  font-family: 'SuisseIntlCond-Light-WebS';
  src: url('../../assets/fonts/SuisseIntlCond-Light-WebS.ttf') format('truetype');
}

@font-face {
  font-family: 'SuisseIntlCond-Regular-WebS';
  src: url('../../assets/fonts/SuisseIntlCond-Regular-WebS.ttf') format('truetype');
}

  .jekformsM {
    width: 10rem;
    height: 2.35rem;
    margin-top: 4%;
    margin-left: 5%;
  }

  .LogoutM{
    width: 5rem;
    height: 1.5rem;
    margin-top: -2.2%;
    margin-left: 85%;
    z-index: 1;
    cursor: pointer;
    transition: transform 2s ease-out;
  }  

  .LogoutButton:hover{
    transform: scale(1.02);
  }

  
  .pesquisa {
    position: relative;
  background-color: #F2F2F2;
  width: 24%;
  height: 0.6rem;
  padding: 1rem 35px;
  margin-top: 5%;
  margin-bottom: 3%;
  border-radius: 6px;
  border: 1px solid #1690C3; /* Adicione "solid" para definir o estilo da borda */
  margin-left: 12%;
  z-index: 1;
  }

  .lupa{
    color: #1690C3;
    position: absolute;
    margin-left: 12.8%;
    margin-top: -5%;
    z-index: 2;
  }

.criabtn {
  display: flex;
  align-items: center;
  margin-bottom: 4%;
}

.createbtn {
  height: 3rem;
  align-items: center;
  margin-left: 50%;
  margin-top: 2%;
  cursor: pointer;
  border-radius: 0.375rem;
  border-color: transparent;
  background: var(--c_azul, #7CCCED);
  padding: 0.5rem 1rem;
  gap: 0.625rem;
  flex-shrink: 0;
  transition: transform 0.2s ease-out;
}

.createbtn{
  font-family: 'SuisseIntlCond-Regular-WebS';
  color: #F2F2F2;
  font-size: 1.5rem;
  text-align: right;
  font-weight: 500;
}

.createbtn:hover{
  transform: scale(1.02);
}

.btn-wrapper {
  width:9.7%;
  display: flex;
  margin-top: auto;
  margin-left: 12%;

}

.Activebtn{
  width: 45%;
  margin-right: 15%;
  cursor: pointer;
}

.Inactivebtn{
  width: 55%;
  cursor: pointer;
}

.Activebtn:hover {
  transform: scale(1.02);
  border-bottom: 2px solid black;
}

.Inactivebtn:hover {
  transform: scale(1.05);
  border-bottom: 2px solid black;
}

@font-face {
  font-family: 'SuisseIntlCond-Regular-WebS';
  src: url('../../assets/fonts/SuisseIntlCond-Regular-WebS.ttf') format('truetype');
}


.column-layout {

  width:72%;
  display: flex;
  flex-direction: column;
  flex-grow: unset;
  margin-left:12%;
  margin-bottom: 5%;

}

@keyframes changeColor {
  0% {
    background: linear-gradient(to right, #7CCCED 5%,#72B2CC 10%,#1690C3 95%);
  }
  33% {
    background: linear-gradient(to right, #7CCCED 10%,#72B2CC 20%,#1690C3 80%);
  }
  66% {
    background: linear-gradient(to right, #7CCCED 20%,#72B2CC 30%,#1690C3 70%);
  }
  100% {
    background: linear-gradient(to right, #7CCCED,#72B2CC,#1690C3);
  }
}


.formulario-button-impar {
  background-color: #1690C3;
  color: white;
  padding: 2vw 2vh;
  border: none;
  border-radius: 5px;
  font-size: 1.5rem;
  width: 40.0%;
  height:1rem;
  margin-bottom: 1vh;
  font-family: 'SuisseIntlCond-Regular-WebS';
  display: flex;
  align-items: center;
  justify-content: right;
  pointer-events: auto; 
}

.formulario-button-par {
  background-color: #1690C3;
  color: white;
  padding: 2vw 2vh;
  border: none;
  border-radius: 5px;
  font-size: 1.5rem;
  width: 40%;
  height:1rem;
  margin-bottom: 1vh;
  font-family: 'SuisseIntlCond-Regular-WebS';
  display: flex;
  align-items: center;
  justify-content: left;
}


.formulario-button-impar{
  position: relative;
  margin-left: auto;
  margin-top: -6.5%;
  margin-bottom: 1.5%;
}



.formulario-button-impar:hover {
  
  cursor: pointer;
  animation: changeColor 0.1s ease 1 forwards;
  pointer-events: auto;
  
}

.formulario-button-par:hover {
  
  cursor: pointer;
  animation: changeColor 0.1s ease 1 forwards;
  
}

.formularios-container {
  display: flex;
  flex-direction: column;
  width: 83rem;
  margin-left: 12%;
}

.button-content{
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-content: center;
  width: 98%;
  height: 5rem;
  margin-left: -1%;
}


.ellipsis- {
  display: flex;
  align-items: center;
  height: 3.5rem;
  transform: scale(1.2);
  margin-left: auto;
  margin-top:-0.6%;
  font-weight: 600;
  z-index: 2;
  transition: transform 0.2s ease-out;
  
}

.ellipsis-:hover{
  transform: scale(1.4);
}

.ellipsis-inactive{
  display: none;
}

.ellipsis-hidden{
  display: none;
}

.dropdown-content{
  display: flex;
  width: 20%;
  margin-left: 1%;
  
}

#ic1
{
  margin-right: 13%;
  transition: transform 0.2s ease-out;
}

#ic1:hover{
    transform: scale(1.1);
}


#ic2
{
  margin-right: 13%;
  transition: transform 0.2s ease-out;
}

#ic2:hover{
    transform: scale(1.1);
}

#ic3
{
  margin-right: 13%;
  transition: transform 0.2s ease-out;
}

#ic3:hover{
    transform: scale(1.1);
}




  @media (min-width: 1513px) {
      
    .createbtn{
      margin-left: 53%;
    }

    .lupa{
      position: absolute;
      margin-top:-4.6%;
      margin-left: 12.5%;
        }
  }

  @media (max-width: 1400px){

     .createbtn{
      margin-left: 47%;
    }

    .formulario-button-impar{
      margin-left:58.3%;
      font-size: 1.2rem;
    }

    .formulario-button-par{
      font-size: 1.2rem;
    }

    .lupa{
      margin-top:-5.4%;
    }
  }