.Template1{
  width: 100%;
  height:100%;
}

.jekformsT1
{

 margin-top: 6%;
 margin-left: 5%;
 width: 15%;
 
 
}

.ContinuarBtn{
    width: 30%;
    margin-top:5%;
    margin-left: 0%;
    z-index: 1;
}

  
  .Tlinha{
    width:14.5rem;
    height: 30rem;
    margin-top:-10.9%;
    margin-left:auto;
    background-color: #D9D9D9;
    border-radius: 0.35rem;
    z-index: -1;
   
  }

  

  .content{
    display: flex;
    width: 60%;
    margin-top: -40%;
    margin-left: 10%;
    z-index: 2;
    align-items: center;
    flex-direction: column;
  }

  .templateInput{
    width:70%;
    height:3rem;
    
  }

  .carbon_image{
    width:100%;
    height: 26%;
    margin-top: 70%;
    cursor: pointer;
    transition: transform 2s ease-out;
  }

  .carbon_image:hover{
    transform: scale(1.02);
  }