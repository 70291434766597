
@font-face {
    font-family: 'SuisseIntlCond-Light-WebS';
    src: url('../../assets/fonts/SuisseIntlCond-Light-WebS.ttf') format('truetype');
  }
  
  @font-face {
    font-family: 'SuisseIntlCond-Regular-WebS';
    src: url('../../assets/fonts/SuisseIntlCond-Regular-WebS.ttf') format('truetype');
  }
  
  

.Template2OP{
    width: 100%;
    height: 100%;
   
  }




.jekformsOP2{
    width: 10rem;
    height: 2.35rem;
    margin-top: 4%;
    margin-left: 5%;
  }

  .contentOP2{
   
    font-family: 'SuisseIntlCond-Regular-WebS';
     color: var(--e_azul, #1690C3);
      width: fit-content;
      text-align: center;
      margin-left: 34%;
      margin-top: 15%;
      font-size: 2.8rem;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
  }


  .ContinuarBtnOP2{
    margin-top: -10%;
    width: 27.875rem;
    height: 3rem;
    flex-shrink: 0;
    border-radius: 0.375rem;
    color: var(--white, #F2F2F2);
    background-color: #1690C3;
    border-color: transparent;
    font-family: 'SuisseIntlCond-Light-WebS';
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    cursor: pointer;
    transition: transform 2s ease-out;
  }

  .ContinuarBtnOP2:hover{
    transform: scale(1.02);
  }


  
  /* responsividade crescente*/
 
  @media (min-width: 1700px){
    
    .contentOP2{
     
        margin-left: 36.5%;
        margin-top: 10%;
      
    }

    
  }

 /* responsividade crescente*/

  @media( max-width: 1355px){

    
 
    .jekformsOP2{
      width: 7rem;
      height: 2.35rem;
      margin-top: 4%;
      margin-left: 5%;
    }
  

    .contentOP2{
     
      margin-left: 35.5%;
      margin-top: 6%;
      font-size: 2.0rem;
    
  }



  .ContinuarBtnOP2{
    
    width: 20.875rem;
    height: 2rem;
   font-size: 1rem;
  }
  }
 
  
  @media( max-width: 390px){

    .jekformsOP2{
      width: 7rem;
      height: 1.9rem;
      margin-top: 10%;
  }


    .ContinuarBtnOP2{
      
      width: 15rem;
      height: 1.6rem;
      font-size: 1rem;
    
    }

    
  .contentOP2{
    margin-left: 5.4rem;
    margin-left: 20%;
    margin-top: 50%;
    font-size: 1rem;
    
}

  }