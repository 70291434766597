
@font-face {
  font-family: 'SuisseIntlCond-Light-WebS';
  src: url('../../assets/fonts/SuisseIntlCond-Light-WebS.ttf') format('truetype');
}

@font-face {
  font-family: 'SuisseIntlCond-Regular-WebS';
  src: url('../../assets/fonts/SuisseIntlCond-Regular-WebS.ttf') format('truetype');
}



  .Template1OP1{
    width: 100%;
    overflow: hidden;
    
  
   
  }

  .jekformsTOP1{
    width: 10rem;
    height: 2.35rem;
    margin-top: 4%;
    margin-left: 5%;
  }


  .contentOP1{
    font-family: 'SuisseIntlCond-Regular-WebS';
     color: var(--e_azul, #1690C3);
      width: fit-content;
      text-align: center;
      margin-left: 15rem;
      margin-top: 15%;
      font-size: 2.8rem;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
  }

  .ContinuarBtnOP1{
    margin-top: -10%;
    width: 27.875rem;
    height: 3rem;
    flex-shrink: 0;
    border-radius: 0.375rem;
    color: var(--white, #F2F2F2);
    background-color: #1690C3;
    border-color: transparent;
    font-family: 'SuisseIntlCond-Light-WebS';
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    cursor: pointer;
    transition: transform 2s ease-out;
  }

  .ContinuarBtnOP1:hover{
    transform: scale(1.02);
  }

  .TlinhaOP1 {
    position: absolute;
    background-color: #D9D9D9;
    width: 40%;
    height: 100%;
    right: 0;
    top: 0;
    

  }

  .TlinhaOP1 img{
    width: 100%;
    height: 100%;
  }

  /* responsividade crescente*/
 
  @media (min-width: 1800px) {

  
    .contentOP1{
   
        margin-left: 20%;
        margin-top: 10%;
        font-size: 2.5rem;
        
    }

    
  

  }

 /* responsividade decrescente*/

  @media( max-width: 1355px) and (max-height: 760px){
 
    
  .jekformsTOP1{
    width: 7rem;
    height: 2.35rem;
    margin-top: 4%;
    margin-left: 5%;
  }

  .contentOP1{
 
      margin-left: 18%;
      margin-top: 10%;
      font-size: 1.5rem;
      
  }

  .ContinuarBtnOP1{
   
    width: 17.875rem;
    height: 2.5rem;
  }


  
  }

  @media( max-width: 900px){
  .Template1OP1{
    display: flex;
    flex-direction: column;
  }

  .jekformsTOP1{
      margin-top: 7%;
        width: 7rem;
    height: 1.9rem;
    margin-top: 10%;
  }
  
  .contentOP1{
 
    margin-left: 22%;
    margin-top: 10%;
    font-size: 1.3rem;
    
}

  .TlinhaOP1{
    width: 28rem;
    height: 35rem;
    position: relative;
    margin-left: 22%;
    margin-top: 6%;
  }
   
  }
 
  @media( max-width: 390px){

    .jekformsTOP1{
      width: 7rem;
      height: 1.9rem;
      margin-top: 10%;
  }
    .TlinhaOP1{
      margin-top: 10%;
      margin-bottom: 10%;
      margin-left: 5.4rem;
      width: 15rem;
      height: 28rem;
      
    }

    .ContinuarBtnOP1{
      
      width: 15rem;
      height: 1.6rem;
      font-size: 1rem;
    
    }

    
  .contentOP1{
    margin-left: 5.4rem;
    margin-left: 22%;
    margin-top: 10%;
    font-size: 1rem;
    
}

  }

