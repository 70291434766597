@font-face {
  font-family: 'SuisseIntlCond-Light-WebS';
  src: url('../../assets/fonts/SuisseIntlCond-Light-WebS.ttf') format('truetype');
}

@font-face {
  font-family: 'SuisseIntlCond-Regular-WebS';
  src: url('../../assets/fonts/SuisseIntlCond-Regular-WebS.ttf') format('truetype');
}

.Template5op {
  width: 100%;
  height:56.4rem;
}

.jekformsOP5{
  width: 10rem;
  height: 2.35rem;
  margin-top: 4%;
  margin-left: 5%;
}

.contentOP5{

      font-family: 'SuisseIntlCond-Light-WebS';
      color: var(--e_azul, #1690C3);
      width: 27.5rem;
      height: 29.375rem;
      text-align: center;
      margin-left: 35%;
      margin-top: 6%;
      font-style: normal;
      font-weight: 700;
      line-height: normal;

}

.calendar{
  margin-bottom: 10%;
}

.titulotop5{
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 5%;
}

.quadradoWrapperOP5{
  width: 27.5rem;
  height: 31rem;
  max-width: 100%;
  background: transparent;
  border: 0.15rem solid #1690C3; /* Cor da borda */
  border-radius: 0.5em;
  font-family: 'SuisseIntlCond-Regular-WebS';
  line-height: 1.125em;
}


.react-calendar button {
    margin: 0;
    border: 0;
    outline: none;
  }
  .react-calendar button:enabled:hover {
    cursor: pointer;
  }


  .react-calendar__navigation {
    display: flex;
    justify-content: center;
    align-items: center; 
    height: 4rem; 
    width: 27.5rem; 
    margin-bottom: 2vh;
    border-bottom: 0.2vw solid #1690C3;;
    border-radius: 0.5em;
  }

  .react-calendar__navigation button  {
    width: 3rem; 
    background: none;
    font-size: 130%; 
    font-family: 'SuisseIntlCond-Light-WebS';
    font-weight: 400; 
    color: #1690C3;
    
}

  .react-calendar__month-view__weekdays {
    text-align: center;
    font-weight: 400;
    font-size: 1.3rem;
    margin-bottom: 5%;
  }
  
  .react-calendar__month-view__weekdays__weekday abbr{
    text-decoration: none;
  }

  .react-calendar__tile{
    font-family: 'SuisseIntlCond-Light-WebS';
    font-weight: bold;
    width: 1.375rem;
    height: 1.9375rem;
    background-color: transparent;
    padding: 1rem;
  }

  .react-calendar__month-view__days__day{
    height: fit-content;
    font-size: 1.3rem;
    
  }

  .react-calendar__month-view__days__day--neighboringMonth {
    color: transparent;
  }

 .react-calendar__navigation__next2-button, .react-calendar__navigation__prev2-button{
  display: none;
 }

 .react-calendar__tile:enabled:hover,
  .react-calendar__tile:enabled:focus {
    background-color: #e6e6e6;
     
  }
  .react-calendar__tile--now {
    color: #76baff;; 
  }
 
  .react-calendar__tile--hasActive {
    background: #76baff; /* Cor dos dias com atividades */
  }
  .react-calendar__tile--hasActive:enabled:hover,
  .react-calendar__tile--hasActive:enabled:focus {
    background: #a9d4ff; 
  }
  .react-calendar__tile--active {
    
    box-sizing:10px;
    border-radius: 4rem;
    background: #1690C3;
    color: #F2F2F2;
}

  .react-calendar__tile--active:enabled:hover,
  .react-calendar__tile--active:enabled:focus {
  
    border-radius: 4rem;
    background: #1690C3;
  }
  .react-calendar--selectRange .react-calendar__tile--hover {
    background-color: #e6e6e6; /* Cor de fundo ao passar o mouse em seleção de faixa */
  }

  .Button-containerop5 {
    width: 5%;
    height: 2.4%;
    margin-top: 10%;
    margin-left:80%;
   
  }
  
  .Button {
    width: 100%;
    height: 100%;
    transition: transform 2s ease-out;
  }
  
  .Button:hover {
    transform: scale(1.02);
    border-bottom: 2px solid #1690C3;
  }

  /* responsividade crescente*/
 
  @media (min-width: 1700px){

    .contentOP5{

      margin-left: 50.5%;
      transform: translateX(-50%);
      margin-top: 3%;
    
}

.Button {
  width: 70%;
 
}


    
}

 /* responsividade crescente*/

  @media( max-width: 1355px){

    .Template5op {
      width: 100%;
      height:8rem;
    }
    

    .jekformsOP5{
      width: 6em;
      height: 2.35rem;
      
    }

    .contentOP5{

      margin-left: 49%;
      transform: translateX(-47%);
      margin-top: 3%;
      width: 15.5rem;
      height: 15.375rem;
    
}

.quadradoWrapperOP5{
  width: fit-content;
  height: fit-content;
  max-width: 100%;
  background: transparent;
  border: 0.15rem solid #1690C3; /* Cor da borda */
  border-radius: 0.5em;
  font-family: 'SuisseIntlCond-Regular-WebS';
  line-height: 1.125em;
}

.Button-containerop5 {
  width: 4%;
  height: 2.4%;
  margin-top: 10%;
  margin-left:84%;
 
}

.react-calendar__tile{
  font-size: 0.9rem;

  font-weight: bold;
  width: 0.2rem;
  height: 2.1rem;
  background-color: transparent;
  
}

.titulotop5{
  font-size: 1.3rem;
}

.react-calendar__month-view__weekdays {
  
  font-size: 1rem;
  margin-bottom: 2%;

}

.react-calendar__navigation {
  display: flex;
  justify-content: center;
  align-items: center; 
  height: 3rem; 
  width: 15.5rem; 
  
}

.Button-containerop5 {
  width: 4.3rem;
  height: 1rem;
  margin-top: 10%;
  margin-left:80%;
 
}

.Button {
  width: 200%;
  height: 200%;
  transition: transform 2s ease-out;
}

.react-calendar__navigation button  {
  background: none;
  font-size: 110%; 
  font-family: 'SuisseIntlCond-Light-WebS';
  font-weight: 400; 
  color: #1690C3;
  
}

.react-calendar__month-view__days__day{
  display:flex;
  align-items: center; 
}

  }

  @media( max-width: 390px){
  
    .jekformsOP5{
      width: 7rem;
      height: 1.9rem;
      margin-top: 10%;
  }

  .ContinuarBtnop3{
      
    width: 15rem;
    height: 1.6rem;
    font-size: 1rem;
  
  }

  .contentOP5{

    margin-left: 49%;
    transform: translateX(-47%);
    margin-top: 30%;
    width: 15.5rem;
    height: 15.375rem;
  
}
 

.caixacheck{
  margin-top: 5%;
  width: 15rem;
  height: 1.6rem;
  font-size: 1rem;

 
}

.logojekOP{
  width: 24.6875rem;
height: 26.0625rem;
  margin-top: -10%;

  margin-left:6%;
}

.Button-containerop5 {
  width: 3.5rem;
  height: 1.4375rem;
  margin-top: 90%;
  margin-left:80%;
 
}

  
  }
 