.Template7{
  width: 100%;
  height: 100%;
}

.jekformsT7{
  margin-top: 6%;
  margin-left: 5%;
  width: 15%;
}

.contentT7{
  
  margin-top: 8%;
  margin-left: 38%;


}


.quadradoWrapperT7{
  width: fit-content;
  margin-left: -34%;
  margin-top: 6%;
  
}

.quadradoWrapperT7 img{
  width: 80%;
  cursor: pointer;
  transition: transform 2s ease-out;
}

.quadradoWrapperT7 img:hover{
  transform: scale(1.02);

}

.DownB{
  cursor: pointer;
}