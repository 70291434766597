
@font-face {
  font-family: 'SuisseIntlCond-Light-WebS';
  src: url('../../assets/fonts/SuisseIntlCond-Light-WebS.ttf') format('truetype');
}

@font-face {
  font-family: 'SuisseIntlCond-Regular-WebS';
  src: url('../../assets/fonts/SuisseIntlCond-Regular-WebS.ttf') format('truetype');
}

.Templateop4{
  width: 100%;
  height: 100%;
  
}

.jekformsop4{
  width: 10rem;
  height: 2.35rem;
  margin-top: 4%;
  margin-left: 5%;
}


.contentOP4{
  text-align: center;
  width: fit-content;
  margin-left: 43%;
  margin-top: 19%;
  font-family: 'SuisseIntlCond-Light-WebS';

}

.logojek3{
  margin-top: -20.5%;
}

.inputop4{
  margin-left: -50%;
  margin-top: 30%;
  width: 200%;
  border-color: transparent;
  background-color: transparent;
  border-bottom-color: #1690C3;
  font-size: 1rem;
  color: #1690C3;
  font-family: 'SuisseIntlCond-Light-WebS';
  text-align: center;
}


.Button-containerop4 {
  width: 5%;
  height: 2.4%;
  margin-top: -10%;
  margin-left:80%;
 
}

.Button {
  width: 100%;
  height: 100%;
  transition: transform 2s ease-out;
}

.Button:hover {
  transform: scale(1.02);
  border-bottom: 2px solid #1690C3;
}

textarea:focus, input:focus, select:focus {
  box-shadow: 0 0 0 0;
  outline: 0;
} 


  /* responsividade crescente*/
 
  @media (min-width: 1700px){

    .contentOP4{
     
      
      margin-top: 10%;
      font-family: 'SuisseIntlCond-Light-WebS';
      margin-left: 45%;
    
    }

    .logojek3{
      width: 40%;
      margin-top: -12.2%;
    }
    
    .Button{
      width: 70%;
      
    }
   
    
  }

 /* responsividade crescente*/

  @media( max-width: 1355px){

    .contentOP4{
      text-align: center;
      width: fit-content;
      margin-left: 45%;
      margin-top: 12%;
      
    }

    .jekformsop4{
      width: 7rem;
      height: 2.35rem;
      margin-top: 4%;
      margin-left: 5%;
    }
  

    .logojek3{
      width:40%;
      margin-top: -18.2%;
    }

    .inputop4{
      margin-left: -22%;
      margin-top: 34%;
      width: 150%;

    }



    .Button-containerop4 {
      width: 5%;
      height: 2.4%;
      margin-top: -10%;
      margin-left:80%;
    
    }
  }
 
  @media( max-width: 390px){

    .jekformsop4{
      width: 7rem;
      height: 1.9rem;
      margin-top: 10%;
  }
  
  
    .ContinuarBtnop3{
      
      width: 15rem;
      height: 1.6rem;
      font-size: 1rem;
    
    }
  
    
  .contentOP4{
    margin-left: 30%;
    margin-top: 50%;
    font-size: 1rem;
    
  }
  
  .caixacheck{
    margin-top: 5%;
    width: 15rem;
    height: 1.6rem;
    font-size: 1rem;
  
   
  }
  
  .logojek3{
    width: 24.6875rem;
    height: 26.0625rem;
    margin-top: 5%;
    margin-left:-20%;
  }
  
  
.Button-containerop4 {
  width: 3.5rem;
  height: 1.4375rem;
  margin-top: -10%;
  margin-left:80%;
 
}

.Button {
  width: 100%;
  height: 100%;
  transition: transform 2s ease-out;
}
  
  }