.Template6{
  width: 100%;
  height: 100%;
}

.jekformsT6{
  margin-top: 6%;
  margin-left: 5%;
  width: 15%;
}

.contentT6{
    width: fit-content;
    margin-top: 7%;
    margin-left: 38%;
    margin-bottom: 4%;

}

.quadradoWrapper{
  width: fit-content;
  display: flex;
  flex-direction:row;
  margin-left: 13%;
  
}

.quadradoWrapper img{
  width: 10rem;
  height:12rem;
  padding: 6px;

}
 
.QA1, .QA2, .QA3
{
  width: fit-content;
  cursor: pointer;
  transition: transform 2s ease-out;
}

.QA1:hover, .QA2:hover, .QA3:hover{
  transform: scale(1.1);
}
