.container {
  display: flex;
  flex-direction: column;
}

.jekformsL {
  width: 10rem;
  height: 2.35rem;
  margin: 5%;
  margin-top: 4%;
}

.signIn {
  margin-top: 10%;
  margin-left: 45%;
}

.google-btn {
  background-color: #4285f4;
  color: #fff;
  border: none;
  padding: 12px 24px;
  font-size: 16px;
  border-radius: 8px;
  cursor: pointer;
  transition: transform 2s ease-out;
}

.google-btn:hover {
  background-color: #7CCCED; 
  transform: translateY(-5px); 
}

    
    
    
    
    