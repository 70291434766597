.Template5{
    width: 100%;
    height: 100%;
}

.jekformsT5{
    margin-top: 6%;
    margin-left: 5%;
    width: 15%;
}

.Calendario{
    width: 38%;
    margin-left: 30%;
    margin-top: 5%;
}

.contentT5{
    margin-left: 38%;
}