.Template3{
  width: 100%;
  height: 100%;
  background-image: url("../../assets/img/T3CBACK.svg");
  
  background-size: cover;
}

.jekformsT3{
  margin-top: 6%;
  margin-left: 5%;
  width: 15%;
}

.contentT3{
  display: flex;
  width: 60%;
  margin-top: 5%;
  margin-left: 20%;
  z-index: 2;
  align-items: center;
  flex-direction: column;
  padding: 1%;
}

.templateTitleT3{
  margin-bottom: 3%;

}

.inputContainer{
  display: flex;
  flex-direction: column;
  margin-bottom: 5%;
}

.templateInputT3{
  margin-bottom: 10%;
}

.Addbox{
  cursor: pointer;
  transition: transform 2s ease-out;
}

.Addbox:hover{
  transform: scale(1.02);
}