.Inipage {
 overflow: hidden;
 height: 100vh;
 width: 100%;
 background-size: cover;
 display: flex;
}

.contentIni {
  width: 90%;
  height: 80%;
  margin: auto;
  margin-top: 4%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.jekforms {
  width: 10rem;
  height: 2.35rem;
}

.text-container {
  position: relative;
  height: fit-content;
  font-size: 100%;
  margin: 5rem  0 0 1.8rem;
  z-index: 1;
}

@font-face {
  font-family: 'SuisseIntlCond-Regular-WebS';
  src: url('../../assets/fonts/SuisseIntlCond-Regular-WebS.ttf') format('truetype');
}

@font-face {
  font-family: 'SuisseIntlCond-Light-WebS';
  src: url('../../assets/fonts/SuisseIntlCond-Light-WebS.ttf') format('truetype');
}

.title {
  font-family: 'SuisseIntlCond-Regular-WebS';
  width: fit-content;
  margin-top: auto;
  font-size: 5rem;
  font-weight: 600;
  
}

.texto {
  font-family: 'SuisseIntlCond-Light-WebS';
  width: fit-content;
  margin-bottom: auto;
  font-size: 4rem;
}

.logojeK {
  width: 60.7%;
  height: 95.8%;
  margin-left: 52.6%;
  margin-top: -22%;
  z-index: 0;
}

.Button-container {
  width: 4%;
  height: 2.4%;
  margin-top: -14%;
  margin-left: 45%;
 
}

.Button {
  width: 100%;
  height: 100%;
  transition: transform 2s ease-out;
}

.Button:hover {
  transform: scale(1.02);
  border-bottom: 2px solid #1690C3;
}

.blue-text {
  color: #1690C3;
}

@media (max-width: 1421px) {
  .jekforms {
    
      width: 7rem;
      height: 2.35rem;

    
  }

  .logojeK{
    width: 70.7%;
    height: 100.8%;
    margin-left: 45.6%;
    margin-top: -35%;
    z-index: 0;
  }

  .text-container {
    position: relative;
    height: fit-content;
    font-size: 100%;
    margin-top:5%;
    
  }


  .title {
    font-size: 3.5rem;
  
  }

  .texto {
    font-size: 2.5rem;
  }

  .Button-container {
    width: 6%;
    height: 3.4%;
    margin-top: -14%;
    margin-left: 45%;
  
    z-index: 1;
  }
}

@media (min-width: 1810px) {

  .logojeK{
    width: 70.7%;
    height: 100.8%;
    margin-left: 45.6%;
    margin-top: -30%;
    z-index: 0;
  }

  .Button-container {
   
  
    z-index: 1;
  }
}