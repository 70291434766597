@font-face {
    font-family: 'SuisseIntlCond-Regular-WebS';
    src: url('../../assets/fonts/SuisseIntlCond-Regular-WebS.ttf') format('truetype');
  }
  
  @font-face {
    font-family: 'SuisseIntlCond-Light-WebS';
    src: url('../../assets/fonts/SuisseIntlCond-Light-WebS.ttf') format('truetype');
  }

.Answer{
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: scroll;
}

.jekformsA{
    align-self: normal;
    width: 10rem;
    height: 2.35rem;
    margin-top: 4%;
    margin-left:5%;
    cursor: pointer;
    transition: transform 2s ease-out;
  }
  
  .jekformsA:hover{
    transform: scale(1.02);
  }


.contentA{
    width:50%;
    text-align: center;
    border: 2.5px solid #1690C3;
    margin-top: 5%;
    font-family: 'SuisseIntlCond-Regular-WebS';
    color: #1690C3;
}

.resposta-group {
    border: 2.5px solid #1690C3;
    margin-bottom: 20px;
    padding: 10px;
  }
  
  .resposta-group h3 {
    margin-bottom: 10px;
  }
  
  .resposta-item {
    border-top: 1px solid #ccc;
    padding-top: 10px;
    margin-top: 10px;
  }
  
  .resposta-item img {
    max-width: 100%;
    height: auto;
  }