
@font-face {
  font-family: 'SuisseIntlCond-Light-WebS';
  src: url('../../assets/fonts/SuisseIntlCond-Light-WebS.ttf') format('truetype');
}

@font-face {
  font-family: 'SuisseIntlCond-Regular-WebS';
  src: url('../../assets/fonts/SuisseIntlCond-Regular-WebS.ttf') format('truetype');
}

.FormsC{
  width: 100%;
  height: 61.2rem;
}


.jekformsFC{
  width: 10rem;
  height: 2.35rem;
  margin-top: 4%;
  margin-left: 5%;
  cursor: pointer;
  transition: transform 2s ease-out;
}

.jekformsFC:hover{
  transform: scale(1.02);
}

.LogoutFC{
  width: 5rem;
  height: 1.5rem;
  margin-top: -2.2%;
  margin-left: 84%;
  z-index: 1;
  cursor: pointer;
  transition: transform 2s ease-out;
}


  .Nomeform{
    background-color: #F2F2F2;
    width: 18%;
    height: 1.5%;
    padding: 12px 35px;
    margin-top: auto;
    border-radius: 2px;
    border: 1px solid #1690C3;
    margin-left: 35%;
    margin-top: 15%;
    z-index: 2;
  }

  .nomebtn{
    background-color: #4285f4;
    color: #fff;
    border: none;
    padding: 12.5px 24px;
    font-size: 90%;
    border-radius: 2px;
    cursor: pointer;
    transition: transform 0.2s ease-in-out;
  }

  .nomebtn:hover{
    transform: scale(1.02);
  }
  


  .add-T{
    width: fit-content;
    height: fit-content;
    display: flex;
    flex-wrap: nowrap;
    align-content: stretch;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    margin-top: 3%;
    margin-left:50.5%;
    transform: translateX(-50%);
    
  }

  .template-example {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 30rem;
    width: 45rem;
    background-color: transparent;
    color: #fff;
    border: 2.5px solid #1690C3;
    border-radius: 0.375rem;
    font-size: 16px;
    z-index: 2;
  }
  

  .add-page-button
  {
    display: flex;
    justify-content: center;
    width: fit-content;
    margin-left: 5%;
    cursor: pointer;
    transition: transform 0.2s ease-in-out;
  }

  .add-page-button:hover{
    transform: scale(1.02);
  }
  



.button-container {
  
    display: flex;
    height: fit-content;
    width: fit-content;
    margin-left: 88%;
    margin-top: 4.5%;
  }

  .Modelobtn-,
  .Geralbtn-{
    font-family: 'SuisseIntlCond-Regular-WebS';
    font-weight: 1000;
    font-size: 100%;
    background: transparent;
    border-color: transparent;
    padding: 0.5rem 1rem;
    cursor: pointer;
  }

  
  .Modelobtn-select,
  .Geralbtn-select {
    font-family: 'SuisseIntlCond-Regular-WebS';
    font-weight: 1000;
    text-decoration: underline 0.10rem;
    font-size: 100%;
    cursor: pointer;
    border-radius: 0.375rem;
    border-bottom-left-radius: 0%;
    border-bottom-right-radius: 0%;
    border-color:  transparent;
    background:  rgba(124, 204, 237, 0.3);
    padding: 0.5rem 1rem;
  
    flex-shrink: 0;
    
  }


  .line {
  
    width: 100%;
    background: rgba(124, 204, 237, 0.3);
    white-space: nowrap;
  }
  
  .image-container {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    padding: 3% 0;
    overflow-x: scroll;
    margin-top: -0%;
    
  }
  
  .image-container img {
    
    width: 14.35rem;
    margin-left: 2%;
    margin-top: 0%;
    margin-right: 1%;
    transition: transform 0.2s ease-in-out;
    cursor: pointer;
    transform: scale(1.07);
  }

  .image-container img:hover{
    transform: scale(1.03);
  }

  .image-container::-webkit-scrollbar {
    width: 5px;
    height: 8px;
  }
  
  .image-container::-webkit-scrollbar-thumb {
    background-color:#1690C3 ;
    border-radius: 6rem; 
    width: 5px;
    height: 8px;
  }
  
  .image-container::-webkit-scrollbar-track {
    background-color: rgba(right, 204, 237, 0.3);
    width: 1%;
  }
  


  @media (max-width: 1478px) {
    
    .button-container{
      margin-top:5.5%;
    }
  }

  @media( max-width: 1285px){

    .button-container{
      margin-top: 7%
    }
  }