.Template4{
    width: 100%;
    height: 100%;
    background-image: url("../../assets/img/T4CBACK.svg");
    background-size: cover;
}

.jekformsT4{
    margin-top: 6%;
    margin-left: 5%;
    width: 15%;
}

.TituloT4{
    margin-top: 15%;
    margin-left: 40%;
}
