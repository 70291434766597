@font-face {
  font-family: 'SuisseIntlCond-Light-WebS';
  src: url('../../assets/fonts/SuisseIntlCond-Light-WebS.ttf') format('truetype');
}

@font-face {
  font-family: 'SuisseIntlCond-Regular-WebS';
  src: url('../../assets/fonts/SuisseIntlCond-Regular-WebS.ttf') format('truetype');
}




.Template7op{
  width: 100%;
  height: 100%;
}

.jekformsOP7{
  width: 10rem;
  height: 2.35rem;
  margin-top: 4%;
  margin-left: 5%;
}

.contentOP7{
  display: flex;
  flex-direction: column;
  text-align: center;
  font-family: 'SuisseIntlCond-Light-WebS';
  margin-top: 12%;
}

.titulotop7{
  margin-bottom: 3%;
}

.Button-containerop7 {
  width: 6%;
  height: 2.4%;
  margin-top: 15%;
  margin-left:87%;
 
}



.DownBOP7{
  transition: transform 2s ease-out;
  cursor: pointer;
}

.DownBOP7:hover{
  transform: scale(1.02);
}

@media(min-width: 1700px)
{

  .contentOP7{
   margin-top: 5%;
   margin-left: 0%;
  }

    
  .Button {
    width: 70%;
  }

}

@media( max-width: 1355px){

  .jekformsOP7{
    width: 7rem;
    height: 2.35rem;
    margin-top: 4%;
    margin-left: 5%;
  }

  .contentOP7{
    display: flex;
    flex-direction: column;
    text-align: center;
    font-family: 'SuisseIntlCond-Light-WebS';
    margin-top: 2%;
  }
  
  .Button-containerop7 {
    width: 6%;
    height: 2.4%;
    margin-top: 6%;
    margin-left:82%;
  }
}


@media( max-width: 390px){
  
  .jekformsOP7{
    width: 7rem;
    height: 1.9rem;
    margin-top: 10%;
}

.titulotop7{
  margin-bottom: 6%;
  font-size: 1rem;
  font-family: 'SuisseIntlCond-Light-WebS';
  font-weight: 400;
}


.contentOP7{

  margin-left: 49%;
  transform: translateX(-47%);
  margin-top: 50%;
  width: 15.5rem;
  height: 15.375rem;

}

.DownBOP7{
  width: 19rem;
  transition: transform 2s ease-out;
  margin-left: -10%;
  cursor: pointer;
}




.Button-containerop7 {
width: 3.5rem;
height: 1.4375rem;
margin-top: 70%;
margin-left:80%;

}


}
