
@font-face {
  font-family: 'SuisseIntlCond-Light-WebS';
  src: url('../../assets/fonts/SuisseIntlCond-Light-WebS.ttf') format('truetype');
}

@font-face {
  font-family: 'SuisseIntlCond-Regular-WebS';
  src: url('../../assets/fonts/SuisseIntlCond-Regular-WebS.ttf') format('truetype');
}

.Templateop3{
  width: 100%;
  height: 100%;
  
}

.jekformsop3{
  width: 10rem;
  height: 2.35rem;
  margin-top: 4%;
  margin-left: 5%;
}


.contentOP3{
  position: relative;
  font-family: 'SuisseIntlCond-Light-WebS';
  color: var(--e_azul, #1690C3);
   width: fit-content;
   text-align: center;
   margin-left: 35%;
   margin-top: 5%;
   font-size: 1.5rem;
   font-style: normal;
   font-weight: 700;
   line-height: normal;
   z-index: 1;
}


.ContinuarBtnop3{
  margin-top: 5%;
  width: 27.875rem;
  height: 3rem;
  flex-shrink: 0;
  border-radius: 0.375rem;
  color: var(--white, #F2F2F2);
  background-color: #1690C3;
  border-color: transparent;
  font-family: 'SuisseIntlCond-Light-WebS';
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  cursor: pointer;
  transition: transform 2s ease-out;
}



.logojekOP3{
    width:50%;
    height:fit-content;
    margin-left: 50%;
    margin-top: -25.2%;
    z-index: 0;
  }

.caixacheck{
  margin-top: 5%;
  width: 27.875rem;
  height: 3rem;
  flex-shrink: 0;
  border-radius: 0.375rem;
  color:#1690C3 ;
  background-color: #F2F2F2;
  border-color: #1690C3;
  font-family: 'SuisseIntlCond-Light-WebS';
  padding: 2%;
  text-align: start;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  cursor: pointer;
  transition: transform 2s ease-out;
  
}

.checkedbox1{
  margin-left: 93%;
  margin-top: -7%;
}

.caixacheck:hover{
  transform: scale(1.02);
}

@media (min-width: 1700px){


  .contentOP3{
    margin-left: 36%;
  }
 

  .logojekOP3{
    width: 40%;
    margin-top: -25.2%;
    float: 0;
    margin-left:60%;
  }
  
  .Button{
    width: 70%;
    
  }
 
  
}


@media( max-width: 1355px){

  .jekformsop3{
    width: 7rem;
    height: 2.35rem;
    margin-top: 4%;
    margin-left: 5%;
  }
  
  .contentOP3{
   
     margin-left: 20%;
     margin-top: 50%;
     font-size: 1rem;
     
  }

  .caixacheck{
    margin-top: 5%;
    width: 20.875rem;
    height: 2.6rem;
    padding: 2%;
    font-size: 1.4rem;
 
    
  }

  .checkedbox1{
    margin-left: 93%;
    margin-top: -9%;
  }
  
  .logojekOP3{
    width:40%;
    height:fit-content;
    margin-left: 60%;
    margin-top: -30.2%;
    z-index: 0;
  }

  .ContinuarBtnop3{
    width: 20.875rem;
    height: 2rem;
   font-size: 1rem;
   
  }
  
    
}


@media( max-width: 390px){

  .jekformsop3{
    width: 7rem;
    height: 1.9rem;
    margin-top: 10%;
}


  .ContinuarBtnop3{
    
    width: 15rem;
    height: 1.6rem;
    font-size: 1rem;
  
  }

  
.contentOP3{
  margin-left: 5.4rem;
  margin-left: 20%;
  margin-top: 50%;
  font-size: 1rem;
  
}

.caixacheck{
  margin-top: 5%;
  width: 15rem;
  height: 1.6rem;
  font-size: 1rem;

 
}

.logojekOP3{
  width: 24.6875rem;
height: 26.0625rem;
  margin-top: -10%;

  margin-left:6%;
}


}