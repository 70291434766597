.Template2{
  width: 100%;
  height: 100%;
}

.jekformsT2{
  margin-top: 6%;
  margin-left: 5%;
  width: 15%;
}


.contentT2{
  display: flex;
  width: 60%;
  margin-top: 15%;
  margin-left: 20%;
  z-index: 2;
  align-items: center;
  flex-direction: column;
}

.TemplateInputT2{
  width:70%;
  height:3rem;
}


.ContinuarBtnT2{
  width: 30%;
  margin-top:5%;
  margin-left: 0%;
  z-index: 1;
}
